import React from 'react';
import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/Asset.png"
import "../components/layout.css"
import bgInside from "../images/bg-inside.png"


const NotFoundPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bg
  } else {
    image = bg
  }

  return (
    <Layout>
      <Seo title="Fausto Tadeu Medicina Dentária" />
      <div className="fullWidth" style={{ backgroundImage: `url(${image})` }}>
        {breakpoints.md || breakpoints.ipad ? (
          <>
            <div class="container">

              <div className='wrapper' style={{ backgroundImage: `url(${bgInside})`, backgroundPosition: "center" }}>
                <div class="principal">
                  <StaticImage
                    src="../images/logo-header.svg"
                    loading="eager"
                    className='image'
                    quality={100}
                    formats={["auto", "webp", "avif", "svg"]}
                    alt=""
                  />
                </div>

                <div class="linha">
                  <ul class="links-list2">
                    <li className='open'>
                      <a href="https://faustotadeu.pt/open-day/" target="_blank" className='day'>
                        Open day Invisalign
                      </a>
                    </li>
                  </ul>
                  {data.globalJson.linktree.map((linktree, l) => (
                    <ul class="links-list">
                      <li>
                        <a href={linktree.link} target="_blank" key={"social" + l}>
                          {linktree.nome}
                        </a>
                      </li>
                    </ul>
                  ))}
                </div>

                <div className='redes'>
                  {data.globalJson.social.map((social, l) => (
                    <a href={social.link} target="_blank" key={"rede" + l}>
                      <GatsbyImage image={getImage(social.img)}
                        quality={100}
                        formats={["auto", "webp", "avif", "svg"]}
                        alt={social.alt}
                      />
                    </a>

                  ))}

                </div>
              </div>
              <div className='site'>
                <Link to='https://faustotadeu.pt/'>
                  <p>FAUSTOTADEU.PT</p>
                </Link>
              </div>
            </div>
          </>)
          :
          (

            <>

              <div class="container">

                <div className='wrapper' style={{ backgroundImage: `url(${bgInside})`, backgroundPosition: "center" }}>
                  <div class="principal">
                    <StaticImage
                      src="../images/logo-header.svg"
                      loading="eager"
                      className='image'
                      quality={100}
                      formats={["auto", "webp", "avif", "svg"]}
                      alt=""
                    />
                  </div>

                  <div class="linha">
                    <ul class="links-list2">
                      <li className='open'>
                        <a href="https://faustotadeu.pt/open-day/" target="_blank" className='day'>
                          Open day Invisalign
                        </a>
                      </li>
                    </ul>
                    {data.globalJson.linktree.map((linktree, l) => (
                      <ul class="links-list">
                        <li>
                          <a href={linktree.link} target="_blank" key={"social" + l}>
                            {linktree.nome}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>

                  <div className='redes'>
                    {data.globalJson.social.map((social, l) => (
                      <a href={social.link} target="_blank" key={"rede" + l}>
                        <GatsbyImage image={getImage(social.img)}
                          quality={100}
                          formats={["auto", "webp", "avif", "svg"]}
                          alt={social.alt}
                        />
                      </a>

                    ))}

                  </div>
                </div>
                <div className='site'>
                  <Link to='https://faustotadeu.pt/'>
                    <p className='siteText'>FAUSTOTADEU.PT</p>
                  </Link>
                </div>
              </div>

            </>
          )}


      </div>

    </Layout>
  )
}

export default NotFoundPage


export const IndexQuery = graphql`
  query notfoundJson {
    globalJson {
      info{
        name
        company
      }
      linktree {
        link
        nome
      }
      social{
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
